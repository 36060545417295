import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Banners/YourHalvaQr"
import Advantages from "../components/Advantages"
import Conditions from "../components/Conditions"
import Partners from "../components/Partners"
import BaseHeader from "../components/Headers/Main"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { FormPKW } from "../components/FormPKW"

export const DEFAULT_ITEMS = [
  {
    sup2: "до",
    count: "10",
    sup: "%",
    text1: "кэшбэк",
    text2: "",
  },
  {
    sup2: "",
    count: "0",
    sup: "₽",
    text1: "оформление",
    text2: "и обслуживание",
  },
  {
    sup2: "до",
    count: <>24</>,
    sup: "",
    text1: "месяца",
    text2: "рассрочки",
  },
]

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout
      seoData={{ title: "Бесплатная карта Халва" }}
      ligal={ligal}
      phones={phones}
      noHeader
      noIndex={noIndex}
    >
      <BaseHeader />
      <Banner orderNum="1" />
      <Advantages items={DEFAULT_ITEMS} variantPosition="yourHalvaQr" orderNum="2" />
      <Conditions head="Невероятные условия" rate="17" orderNum="3" />
      <FormPKW
        hasCityWithOffices
        hasBirth
        dataLayerName="shortPersonalForm"
        title="Заполните заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="4"
        subTitle=""
        emptyForm={false}
        noGridOnMobForOffice
        productName="Osago"
      />
      <Partners orderNum="5" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://gethalva.ru/your-halva-qr/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
